@font-face {
    font-family: 'title font';
    src: url('/public/fonts/causten-extrabold.otf') format('opentype');
  }

  @font-face {
    font-family: 'description font';
    src: url('/public/fonts/dejavusans-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sub sud font';
    src: url('/public/fonts/sourcecodepro-regular.ttf') format('truetype');
  }


.email-hold{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    right: -70px;
    transform: translateY(30%);
}

.email-div{
    display: flex;
    flex-direction: column;
    padding: 0px;
    height: 250px;
}

.email{
    
    font-size: 1em;
    color: #999;
    cursor: pointer;
    transform: rotate(90deg);
    margin-left: -120px;
    padding: 0;
    font-family: 'sub sud font', sans-serif;
    text-decoration: none;
}

.email:hover{
  font-size: 1.01em;
  color: var(--accent-color);
  cursor: pointer;
}

.line {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }
  
  hr{
      margin: 0;
      padding: 0;
  }
  
  .soc-line {
    width: 300px;
    height: auto;
    border: 1px solid #999;
    transform: rotate(90deg);
    animation: color-change 15s ease-in-out infinite;
    margin: 0;
    padding: 0;
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .email-hold{
      display: none;
  }
  }

  @media only screen and (max-width: 767px) {
    .email-hold{
      display: none;
  }
  }
:root {
  --background: #020122;
  --text-color-white: #f8f8f8;
  --image-overlay: #6e0e0a;
  --accent-color: #5cf64a;
}

html{
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) var(--background);
}

html::-webkit-scrollbar{
  width: 0.5vw;
}

html::-webkit-scrollbar-track{
  background-color: var(--background);
}

html::-webkit-scrollbar-thumb{
  background-color: var(--accent-color);
}

  @font-face {
    font-family: 'title font';
    src: url('/fonts/causten-extrabold.otf') format('opentype');
  }

  @font-face {
    font-family: 'description font';
    src: url('/fonts/dejavusans-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sub sud font';
    src: url('/fonts/sourcecodepro-regular.ttf') format('truetype');
  }

  body {
    background-color: var(--background);
    color: var(--text-color-white);
    margin: 10px 100px;
    overflow-x: hidden;     
  }

  .lottie-view{
    width: 100%;
    height: 90vh;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .lot-img{
    width: 20%;
  }

 @keyframes rotate {
  from {
    rotate: 0deg;
  }
  
  50% {
    scale: 1 1.5;
  }
  
  to {
    rotate: 360deg; 
  }
}

#blob {
  background-color: white;
  height: 30vmax;
  aspect-ratio: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -60% -50%;
  border-radius: 50%;
  background: linear-gradient(to right, #4af0f6, #1aff00);
  animation: rotate 20s infinite ease forwards 5s;
  opacity: 0.3;
  filter: blur(50px);
}

@media only screen and (max-width: 767px) {
  body{
    margin: 0px 20px;
    overflow-x: hidden;
  }
  html{
    margin: 0;
  }

  .lot-img{
    width: 50%;
  }

  
}
  :root {
    --background: #020122;
    --text-color-white: #f8f8f8;
    --image-overlay: #6e0e0a;
    --accent-color: #5cf64a;
  }

.main-soc{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 40px;
    transform: translateY(-3%);
    
    
}

.soc-icons{
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
}

.icn{
    padding: 10px 0px 10px 10px;
    font-size: 2em;
    color: var(--accent-color);
    z-index: 2;
    width: 15%;
}


.icn:hover{
  transform: scale(1.08);
  color: var(--accent-color);
  cursor: pointer;
  color: #ffffff;
}

.line {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: -125px;
  padding: 0;

}

hr{
    margin: 0;
    padding: 0;
}

.soc-line {
  width: 300px;
  height: auto;
  border: 1px solid #999;
  transform: rotate(90deg);
  animation: color-change 15s ease-in-out infinite;
  margin: 0;
  padding: 0;
}

@keyframes color-change {
  0% {
    border: 1px solid #999;
  }
  25% {
    border: 1px solid red;
  }

  50% {
    border: 1px solid green;
  }
  100% {
    border: 1px solid #999;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .main-soc{
    display: none;
}
}

@media only screen and (max-width: 767px) {
  .main-soc{
    display: none;
}
}
:root {
    --background: #020122;
    --text-color-white: #f8f8f8;
    --image-overlay: #6e0e0a;
    --accent-color: #5cf64a;
  }

  @font-face {
    font-family: 'title font';
    src: url('/public/fonts/causten-extrabold.otf') format('opentype');
  }

  @font-face {
    font-family: 'description font';
    src: url('/public/fonts/dejavusans-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sub sud font';
    src: url('/public/fonts/sourcecodepro-regular.ttf') format('truetype');
  }

.maiin-comp{
    padding: 0 100px;
}

#blob{
  margin: 100px 0px 0px 400px;
  z-index: 1;
}

.hero{
  margin-top: 150px;
  height: 100vh;
}

.my-name {
    font-family: 'sub sud font', sans-serif;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    color: var(--accent-color);
  }

  .light-shade{
    color: #a6c9ff;
    font-family: 'title font', sans-serif;
    font-size: 60px;
    margin: 10px 0 0 0;
    padding: 0;
    width: 80%;
  }

  .dark-shade{
    color: #5a6e8d;
    font-family: 'title font', sans-serif;
    font-size: 60px;
    margin: 0;
    padding: 0;
    width: 80%;
  }

  .description{
    font-family: 'sub sud font', sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0;
    padding: 0;
    width: 60%;
    color: #f7f7f7;
    line-height: 25px;
  }

  #green{
    color: var(--accent-color);
  }

  .cta{
    padding: 20px 50px;
    margin: 50px 0;
    background-color: var(--background);
    color: #fff;
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    cursor: pointer;
    font-family: 'sub sud font', sans-serif;
    font-size: 15px;
    transition: box-shadow 0.3s, transform 0.3s;
  }

  .cta:hover {
    box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
    transform: scale(1.02);
  }

.about-sec{
    
    width: auto;
    align-items: center;
    padding: 0px 100px 50px 100px;
}

.about{
    display: flex;
    align-items: center;
    padding-bottom: 50px;
}

.no{
    font-size: 25px;
    margin-right: 10px;
    font-family: 'sub sud font', sans-serif;
}

.ab-line{
    width: 300px;
    margin-left: 20px;
    color: var(--accent-color);
}

.ab-txt{
    font-family: 'description', sans-serif;
}

.about-wrapper{
    display: flex;
    justify-content: center;
    align-items: top;
    width: 100%;
}

.green-pop{
  color: var(--accent-color);
}

.line1{
  width: 100%;
  display: flex;
}

#bop{
  width: 40%;
  font-size: 12px;
  text-align: left;
  font-family: 'sub sud font', sans-serif;
}

.log1{
  color: var(--accent-color);
  margin: 15px 5px 0px 0px;
}

.wrap-text{
    width: 60%;
}

.wrap-img{
    width: 40%;
    margin-left: 20px;
    align-items: top;
    height: 100%;
}

.about-w-txt{
  margin: 0;
  padding: 0;
  font-family: 'sub sud font', sans-serif;
  font-size: 14px;
}

.mee {
  display:flex;
  z-index: 1;
  
}

.mee::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background-color: var(--accent-color);
  opacity: 0.5;
  transition: opacity 0.5s;
}

.mee:hover::before {
  opacity: 0;
}

.me{
  width: 300px;
  height: 300px;
  border-radius: 5px;
  filter: grayscale(100%);
  transition: filter 0.5s; 
  z-index: -1;
  transition: box-shadow 0.3s, transform 0.3s;
}


.mee:hover .me {
  filter: grayscale(0%);
  box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
  transform: scale(1.02);
}

.exp-sec{
  width: auto;
  padding: 150px 100px;
}

.expience-sec{
  width: auto;
  padding: 150px 100px;
}

.accordion-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.menu-container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.menu-item {
  
  border-top: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'description', sans-serif;
  font-size: 10px;
  padding: 0px 10px;
  border-radius: 10px;
  text-align: left;
  width: 200px;
}

.menu-item,h2{
  margin: 0;
  padding: 7px;
}

.menu-item:hover {
  background-color: #272650c4;
  padding: 7px;
  border-radius: 10px;
}

.menu-item.active {
  color: var(--accent-color);
  font-weight: bold;
}

.acc-title{
  font-family: 'description', sans-serif;
  margin: 0;
}

.greeny, .dates{
  color: var(--accent-color);
}

.content-item {
  display: none;
  padding-top: 10px;
  font-family: 'sub sud font', sans-serif;
  width: 70%; 
}

.content-item.active {
  display: block;
}

.experience-txt{
  font-family: 'sub sud font', sans-serif;
  font-size: 15px;
}

.ep-wrapper{
  margin-top: 10px;
  background-color: #272650c4;
  text-align: left;
  border-radius: 10px;
}

.ep{
  display: flex;
  align-items: top;
  padding: 10px 0px;
  
}

.ep,p{
  margin: 0;
}

.log{
  padding: 2px 5px;
  width: 18px;
}

.exp-wrapper{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.left-side{
  width: 100%;
  height: 370px;
  margin-right: -150px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Felimu-world-landing.png?alt=media&token=4310147a-5e50-449f-b7ac-968de60df351&_gl=1*1jecd6e*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY0ODguMC4wLjA.);
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
  z-index: -1;
  opacity: 0.4;
}

.ndleft-side{
  width: 100%;
  height: 390px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Fprep-landing.png?alt=media&token=6500f777-4b51-4629-a8e8-7f8b03ea13cb&_gl=1*1k84rkv*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY0NjAuMC4wLjA.);
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
  order: 2;
  margin-left: -150px;
  z-index: -1;
  opacity: 0.4;
}

.rdleft-side{
  width: 100%;
  height: 340px;
  margin-right: -150px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Flearn-landing.png?alt=media&token=6a2fe401-23e1-4a47-abcf-8a4b2ba768b1&_gl=1*uze9iv*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjYzNTQuMC4wLjA.);
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
  z-index: -1;
  opacity: 0.4;
}

.right-side{
  text-align: right;
  width: 50%;
  order: 2;
}

.ndright-side{
  text-align: left;
  width: 50%;
  order: 1;
}

.rdright-side{
  text-align: right;
  width: 50%;
  order: 2;
}

.feat-txt{
  font-family: 'sub sud font', sans-serif;
  font-size: 12px;
  color: var(--accent-color);
}

.project-title{
  font-family: 'description', sans-serif;
  font-weight: bold;
  font-size: 29px;
  padding: 5px 0px;
}

.project-description{
  font-family: 'sub sud font', sans-serif;
  font-size: 15px;
  background-color: #272650c4;
  padding: 10px;
  border-radius: 5px;
}

.project-footer{
  color: var(--accent-color);
  padding: 5PX 0PX;
}

.ace:hover{
  transform: scale(1.05);
}

.stack{
  padding: 10px 5px;
  font-family: 'sub sud font', sans-serif;
  font-size: 12px;
}

.stack-icn,a{
  padding: 5px 5px;
  font-family: 'sub sud font', sans-serif;
  font-size: 20px;
  align-items: center;
}

.stack-icn:hover,a{
  padding: 6px 6px;
  color: #f7f7f7;
  font-family: 'sub sud font', sans-serif;
  font-size: 20px;
  align-items: center;
}

.project-spans{
  display: flex;
  vertical-align: center;
}

.contact-sec{
  text-align: center;
  padding: 150px 0;
  
}


.cont-txt{
  font-family: 'description', sans-serif;
  font-size: 35px;
  padding: 10px 0;
}

.cont-description{
  font-family: 'sub sud font', sans-serif;
  padding: 10px 150px;
  margin-bottom: 70px;
}

.say-hello{
    padding: 10px 30px;
    background-color: var(--background);
    color: #fff;
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-family: 'sub sud font', sans-serif;
    text-decoration: none;
    transition: box-shadow 0.3s, transform 0.3s;
}

.say-hello:hover{
  box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
  transform: scale(1.08);
}

.mobile-soc-icons{
  display: none;
}

@media screen and (min-width: 1400px){
  .maiin-comp{
    padding: 0 250px;
}

.hero{
  padding-top: 150px;
  height: 100vh;
}

}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .maiin-comp{
    padding: 0 0px;
}

#blob{
  display: none;
}

.hero{
  padding-top: 10px;
  height: 100vh;
  margin-top: 150px;
}

.about-sec{
    
  width: 100%;
  align-items: center;
  padding: 50px 0px;
}

.exp-sec{
  width: 100%;
  padding: 50px 0px;
}

.expience-sec{
  width: 100%;
  padding: 50px 0px;
}

.mobile-soc-icons{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.micn{
  padding: 10px 0px;
    font-size: 2em;
    color: var(--accent-color);
    z-index: 2;
    width: 15%;
}

.micn:hover{
  font-size: 2.2em;
  color: var(--accent-color);
  cursor: pointer;
  color: #ffffff;
}

}

@media only screen and (max-width: 767px) {

  html{
    margin: 0;
  }

  .maiin-comp{
    padding: 0 0px;
}

#blob{
  display: none;
}

.hero{
  padding-top: 10px;
  height: 80vh;
}

.light-shade{
  font-size: 30px;
  width: 100%;
}

.dark-shade{
  font-size: 30px;
  width: 100%;
}

.description{
  font-size: 15px;
  width: 90%;
}

.about-sec{
    
  width: 100%;
  align-items: center;
  padding: 50px 0px;
}

.about-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
}

.wrap-text{
  width: 100%;
}

.wrap-img{
  margin-top: 20px;
  width: 100%;
}

.exp-sec{
  width: 100%;
  padding: 50px 0px;
}

.expience-sec{
  width: 100%;
  padding: 50px 0px;
}

.ab-txt{
 width: auto;
}

.ab-line{
  width: 50%;
}

.left-side{
  display: none;
}

.ndleft-side{
  display: none;
}

.rdleft-side{
  display: none;
}

.right-side{
  text-align: left;
  width: 100%;
  order: 2;
}

.ndright-side{
  text-align: left;
  width: 100%;
  order: 2;
}

.rdright-side{
  text-align: left;
  width: 100%;
  order: 2;
}

.proj-img{
  width: 100%;
  height: 200px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Felimu-world-landing.png?alt=media&token=4310147a-5e50-449f-b7ac-968de60df351&_gl=1*1jecd6e*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY0ODguMC4wLjA.) ;
  margin-bottom: -50px;
  border-radius: 5px;
  background-position: center;
  border-radius: 5px;
  background-size: 100%;

}

.ndproj-img{
  width: 100%;
  height: 200px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Fprep-landing.png?alt=media&token=6500f777-4b51-4629-a8e8-7f8b03ea13cb&_gl=1*r7oitr*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY1MzAuMC4wLjA.) ;
  margin-bottom: -50px;
  border-radius: 5px;
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
}

.rdproj-img{
  width: 100%;
  height: 200px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Flearn-landing.png?alt=media&token=6a2fe401-23e1-4a47-abcf-8a4b2ba768b1&_gl=1*qn2r5t*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY2NzcuMC4wLjA.) ;
  margin-bottom: -50px;
  border-radius: 5px;
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
}



.accordion-container{
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
}

.menu-container{
  display: flex;
  flex-direction: row;
  width: 99%;
}

.content-container{
  width: 100%;
}

.content-item {
  display: none;
  padding-top: 10px;
  font-family: 'sub sud font', sans-serif;
  width: 100%; 
}

.expience-sec{
  width: auto;
  padding: 50px 0px;
}

.contact-sec{
  padding: 50px 0;
}

.cont-description{
  padding: 10px 0px;
}

.mobile-soc-icons{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.micn{
  padding: 10px 0px;
    font-size: 2em;
    color: var(--accent-color);
    z-index: 2;
    width: 15%;
}

.micn:hover{
  font-size: 2.2em;
  color: var(--accent-color);
  cursor: pointer;
  color: #ffffff;
}
}
:root {
  --background: #020122;
  --text-color-white: #f8f8f8;
  --image-overlay: #6e0e0a;
  --accent-color: #5cf64a;
}

@font-face {
  font-family: 'title font';
  src: url('../fonts/causten-extrabold.otf') format('opentype');
}

@font-face {
  font-family: 'description font';
  src: url('../fonts/dejavusans-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'sub sud font';
  src: url('../fonts/sourcecodepro-regular.ttf') format('truetype');
}

.navigation-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background);
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.mobile-navigation-main {
  display: none;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 40px 100px;
}

#name {
  text-align: left;
}

#name:hover {
  cursor: pointer;
}

.navigation-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.nav-section {
  list-style-type: none;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-family: 'sub sud font', sans-serif;
}



.num {
  color: var(--accent-color);
}

.resume-btn {
  padding: 10px 30px;
  margin-left: 10px;
  background-color: var(--background);
  color: #fff;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'sub sud font', sans-serif;
  transition: box-shadow 0.3s, transform 0.3s;
}

.resume-btn:hover {
  box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
  transform: scale(1.02);
}

#link{
  cursor: pointer;
  font-size: 15px;
}

#link:hover{
  color: var(--accent-color);
  transform: scale(1.02);
}

#logo {
  animation: fill-color 3s ease forwards 6s;
}

#logo:hover {
  animation: fill-color 3s ease forwards 6s;
  transform: scale(1.02);
}

#logo path:nth-child(1) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  animation: line-anim 6s ease forwards;
}

#logo path:nth-child(2) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  animation: line-anim 7s ease forwards;
}

#logo path:nth-child(3) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  animation: line-anim 8s ease forwards;
}

#logo path:nth-child(4) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  animation: line-anim 9s ease forwards;
}

#logo path:nth-child(5) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  animation: line-anim 10s ease forwards;
}

#logo path:nth-child(6) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  animation: line-anim 11s ease forwards;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-color {
  from {
    fill: transparent;
  }

  to {
    fill: var(--accent-color);
  }
}

@media only screen and (max-width: 850px) {
  .navigation-main {
    display: none;
  }

  .mobile-navigation-main {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
  }


  .mobile-name-logo {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;
  }

  .mobile-navigation-section {
    text-align: left;
    width: 100%;
    z-index: 1;
  }

  .nav-section {
    padding: 10px 0px;
  }

  .resume-btn {
    margin: 0;
    width: 100%;
  }

  .mobile-navigation-section.open {
    display: block;
    animation-name: slide-down;
    animation-duration: 2s;
    animation-timing-function: ease-out;
  }

  @keyframes slide-down {
    0% {

      opacity: 0;
    }

    100% {

      opacity: 1;
    }
  }

  .close {
    display: none;
  }
}

:root {
  --background: #020122;
  --text-color-white: #f8f8f8;
  --image-overlay: #6e0e0a;
  --accent-color: #5cf64a;
}

html{
  scrollbar-width: thin;
  scrollbar-color: #5cf64a #020122;
  scrollbar-color: var(--accent-color) var(--background);
}

html::-webkit-scrollbar{
  width: 0.5vw;
}

html::-webkit-scrollbar-track{
  background-color: #020122;
  background-color: var(--background);
}

html::-webkit-scrollbar-thumb{
  background-color: #5cf64a;
  background-color: var(--accent-color);
}

  @font-face {
    font-family: 'title font';
    src: url('/fonts/causten-extrabold.otf') format('opentype');
  }

  @font-face {
    font-family: 'description font';
    src: url('/fonts/dejavusans-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sub sud font';
    src: url('/fonts/sourcecodepro-regular.ttf') format('truetype');
  }

  body {
    background-color: #020122;
    background-color: var(--background);
    color: #f8f8f8;
    color: var(--text-color-white);
    margin: 10px 100px;
    overflow-x: hidden;     
  }

  .lottie-view{
    width: 100%;
    height: 90vh;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .lot-img{
    width: 20%;
  }

 @-webkit-keyframes rotate {
  from {
    rotate: 0deg;
  }
  
  50% {
    scale: 1 1.5;
  }
  
  to {
    rotate: 360deg; 
  }
}

 @keyframes rotate {
  from {
    rotate: 0deg;
  }
  
  50% {
    scale: 1 1.5;
  }
  
  to {
    rotate: 360deg; 
  }
}

#blob {
  background-color: white;
  height: 30vmax;
  aspect-ratio: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -60% -50%;
  border-radius: 50%;
  background: linear-gradient(to right, #4af0f6, #1aff00);
  -webkit-animation: rotate 20s infinite ease forwards 5s;
          animation: rotate 20s infinite ease forwards 5s;
  opacity: 0.3;
  -webkit-filter: blur(50px);
          filter: blur(50px);
}

@media only screen and (max-width: 767px) {
  body{
    margin: 0px 20px;
    overflow-x: hidden;
  }
  html{
    margin: 0;
  }

  .lot-img{
    width: 50%;
  }

  
}
:root {
  --background: #020122;
  --text-color-white: #f8f8f8;
  --image-overlay: #6e0e0a;
  --accent-color: #5cf64a;
}

@font-face {
  font-family: 'title font';
  src: url(/static/media/causten-extrabold.21abbbfe.otf) format('opentype');
}

@font-face {
  font-family: 'description font';
  src: url(/static/media/dejavusans-regular.eccb7a74.ttf) format('truetype');
}

@font-face {
  font-family: 'sub sud font';
  src: url(/static/media/sourcecodepro-regular.846ad017.ttf) format('truetype');
}

.navigation-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #020122;
  background-color: var(--background);
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.mobile-navigation-main {
  display: none;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 40px 100px;
}

#name {
  text-align: left;
}

#name:hover {
  cursor: pointer;
}

.navigation-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.nav-section {
  list-style-type: none;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-family: 'sub sud font', sans-serif;
}



.num {
  color: #5cf64a;
  color: var(--accent-color);
}

.resume-btn {
  padding: 10px 30px;
  margin-left: 10px;
  background-color: #020122;
  background-color: var(--background);
  color: #fff;
  border: 1px solid #5cf64a;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'sub sud font', sans-serif;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.resume-btn:hover {
  box-shadow: 0 0 10px #5cf64a, 0 0 20px #5cf64a;
  box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

#link{
  cursor: pointer;
  font-size: 15px;
}

#link:hover{
  color: #5cf64a;
  color: var(--accent-color);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

#logo {
  -webkit-animation: fill-color 3s ease forwards 6s;
          animation: fill-color 3s ease forwards 6s;
}

#logo:hover {
  -webkit-animation: fill-color 3s ease forwards 6s;
          animation: fill-color 3s ease forwards 6s;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

#logo path:nth-child(1) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  -webkit-animation: line-anim 6s ease forwards;
          animation: line-anim 6s ease forwards;
}

#logo path:nth-child(2) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  -webkit-animation: line-anim 7s ease forwards;
          animation: line-anim 7s ease forwards;
}

#logo path:nth-child(3) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  -webkit-animation: line-anim 8s ease forwards;
          animation: line-anim 8s ease forwards;
}

#logo path:nth-child(4) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  -webkit-animation: line-anim 9s ease forwards;
          animation: line-anim 9s ease forwards;
}

#logo path:nth-child(5) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  -webkit-animation: line-anim 10s ease forwards;
          animation: line-anim 10s ease forwards;
}

#logo path:nth-child(6) {
  stroke-dasharray: 140px;
  stroke-dashoffset: 140px;
  -webkit-animation: line-anim 11s ease forwards;
          animation: line-anim 11s ease forwards;
}

@-webkit-keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes fill-color {
  from {
    fill: transparent;
  }

  to {
    fill: #5cf64a;
    fill: var(--accent-color);
  }
}

@keyframes fill-color {
  from {
    fill: transparent;
  }

  to {
    fill: #5cf64a;
    fill: var(--accent-color);
  }
}

@media only screen and (max-width: 850px) {
  .navigation-main {
    display: none;
  }

  .mobile-navigation-main {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
  }


  .mobile-name-logo {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;
  }

  .mobile-navigation-section {
    text-align: left;
    width: 100%;
    z-index: 1;
  }

  .nav-section {
    padding: 10px 0px;
  }

  .resume-btn {
    margin: 0;
    width: 100%;
  }

  .mobile-navigation-section.open {
    display: block;
    -webkit-animation-name: slide-down;
            animation-name: slide-down;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  @-webkit-keyframes slide-down {
    0% {

      opacity: 0;
    }

    100% {

      opacity: 1;
    }
  }

  @keyframes slide-down {
    0% {

      opacity: 0;
    }

    100% {

      opacity: 1;
    }
  }

  .close {
    display: none;
  }
}
:root {
    --background: #020122;
    --text-color-white: #f8f8f8;
    --image-overlay: #6e0e0a;
    --accent-color: #5cf64a;
  }

  @font-face {
    font-family: 'title font';
    src: url('/public/fonts/causten-extrabold.otf') format('opentype');
  }

  @font-face {
    font-family: 'description font';
    src: url('/public/fonts/dejavusans-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sub sud font';
    src: url('/public/fonts/sourcecodepro-regular.ttf') format('truetype');
  }

.maiin-comp{
    padding: 0 100px;
}

#blob{
  margin: 100px 0px 0px 400px;
  z-index: 1;
}

.hero{
  margin-top: 150px;
  height: 100vh;
}

.my-name {
    font-family: 'sub sud font', sans-serif;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    color: #5cf64a;
    color: var(--accent-color);
  }

  .light-shade{
    color: #a6c9ff;
    font-family: 'title font', sans-serif;
    font-size: 60px;
    margin: 10px 0 0 0;
    padding: 0;
    width: 80%;
  }

  .dark-shade{
    color: #5a6e8d;
    font-family: 'title font', sans-serif;
    font-size: 60px;
    margin: 0;
    padding: 0;
    width: 80%;
  }

  .description{
    font-family: 'sub sud font', sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0;
    padding: 0;
    width: 60%;
    color: #f7f7f7;
    line-height: 25px;
  }

  #green{
    color: #5cf64a;
    color: var(--accent-color);
  }

  .cta{
    padding: 20px 50px;
    margin: 50px 0;
    background-color: #020122;
    background-color: var(--background);
    color: #fff;
    border: 1px solid #5cf64a;
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    cursor: pointer;
    font-family: 'sub sud font', sans-serif;
    font-size: 15px;
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;
  }

  .cta:hover {
    box-shadow: 0 0 10px #5cf64a, 0 0 20px #5cf64a;
    box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }

.about-sec{
    
    width: auto;
    align-items: center;
    padding: 0px 100px 50px 100px;
}

.about{
    display: flex;
    align-items: center;
    padding-bottom: 50px;
}

.no{
    font-size: 25px;
    margin-right: 10px;
    font-family: 'sub sud font', sans-serif;
}

.ab-line{
    width: 300px;
    margin-left: 20px;
    color: #5cf64a;
    color: var(--accent-color);
}

.ab-txt{
    font-family: 'description', sans-serif;
}

.about-wrapper{
    display: flex;
    justify-content: center;
    align-items: top;
    width: 100%;
}

.green-pop{
  color: #5cf64a;
  color: var(--accent-color);
}

.line1{
  width: 100%;
  display: flex;
}

#bop{
  width: 40%;
  font-size: 12px;
  text-align: left;
  font-family: 'sub sud font', sans-serif;
}

.log1{
  color: #5cf64a;
  color: var(--accent-color);
  margin: 15px 5px 0px 0px;
}

.wrap-text{
    width: 60%;
}

.wrap-img{
    width: 40%;
    margin-left: 20px;
    align-items: top;
    height: 100%;
}

.about-w-txt{
  margin: 0;
  padding: 0;
  font-family: 'sub sud font', sans-serif;
  font-size: 14px;
}

.mee {
  display:flex;
  z-index: 1;
  
}

.mee::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background-color: #5cf64a;
  background-color: var(--accent-color);
  opacity: 0.5;
  transition: opacity 0.5s;
}

.mee:hover::before {
  opacity: 0;
}

.me{
  width: 300px;
  height: 300px;
  border-radius: 5px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: -webkit-filter 0.5s;
  transition: filter 0.5s;
  transition: filter 0.5s, -webkit-filter 0.5s; 
  z-index: -1;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;
}


.mee:hover .me {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  box-shadow: 0 0 10px #5cf64a, 0 0 20px #5cf64a;
  box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.exp-sec{
  width: auto;
  padding: 150px 100px;
}

.expience-sec{
  width: auto;
  padding: 150px 100px;
}

.accordion-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.menu-container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.menu-item {
  
  border-top: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'description', sans-serif;
  font-size: 10px;
  padding: 0px 10px;
  border-radius: 10px;
  text-align: left;
  width: 200px;
}

.menu-item,h2{
  margin: 0;
  padding: 7px;
}

.menu-item:hover {
  background-color: #272650c4;
  padding: 7px;
  border-radius: 10px;
}

.menu-item.active {
  color: #5cf64a;
  color: var(--accent-color);
  font-weight: bold;
}

.acc-title{
  font-family: 'description', sans-serif;
  margin: 0;
}

.greeny, .dates{
  color: #5cf64a;
  color: var(--accent-color);
}

.content-item {
  display: none;
  padding-top: 10px;
  font-family: 'sub sud font', sans-serif;
  width: 70%; 
}

.content-item.active {
  display: block;
}

.experience-txt{
  font-family: 'sub sud font', sans-serif;
  font-size: 15px;
}

.ep-wrapper{
  margin-top: 10px;
  background-color: #272650c4;
  text-align: left;
  border-radius: 10px;
}

.ep{
  display: flex;
  align-items: top;
  padding: 10px 0px;
  
}

.ep,p{
  margin: 0;
}

.log{
  padding: 2px 5px;
  width: 18px;
}

.exp-wrapper{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.left-side{
  width: 100%;
  height: 370px;
  margin-right: -150px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Felimu-world-landing.png?alt=media&token=4310147a-5e50-449f-b7ac-968de60df351&_gl=1*1jecd6e*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY0ODguMC4wLjA.);
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
  z-index: -1;
  opacity: 0.4;
}

.ndleft-side{
  width: 100%;
  height: 390px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Fprep-landing.png?alt=media&token=6500f777-4b51-4629-a8e8-7f8b03ea13cb&_gl=1*1k84rkv*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY0NjAuMC4wLjA.);
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
  order: 2;
  margin-left: -150px;
  z-index: -1;
  opacity: 0.4;
}

.rdleft-side{
  width: 100%;
  height: 340px;
  margin-right: -150px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Flearn-landing.png?alt=media&token=6a2fe401-23e1-4a47-abcf-8a4b2ba768b1&_gl=1*uze9iv*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjYzNTQuMC4wLjA.);
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
  z-index: -1;
  opacity: 0.4;
}

.right-side{
  text-align: right;
  width: 50%;
  order: 2;
}

.ndright-side{
  text-align: left;
  width: 50%;
  order: 1;
}

.rdright-side{
  text-align: right;
  width: 50%;
  order: 2;
}

.feat-txt{
  font-family: 'sub sud font', sans-serif;
  font-size: 12px;
  color: #5cf64a;
  color: var(--accent-color);
}

.project-title{
  font-family: 'description', sans-serif;
  font-weight: bold;
  font-size: 29px;
  padding: 5px 0px;
}

.project-description{
  font-family: 'sub sud font', sans-serif;
  font-size: 15px;
  background-color: #272650c4;
  padding: 10px;
  border-radius: 5px;
}

.project-footer{
  color: #5cf64a;
  color: var(--accent-color);
  padding: 5PX 0PX;
}

.ace:hover{
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.stack{
  padding: 10px 5px;
  font-family: 'sub sud font', sans-serif;
  font-size: 12px;
}

.stack-icn,a{
  padding: 5px 5px;
  font-family: 'sub sud font', sans-serif;
  font-size: 20px;
  align-items: center;
}

.stack-icn:hover,a{
  padding: 6px 6px;
  color: #f7f7f7;
  font-family: 'sub sud font', sans-serif;
  font-size: 20px;
  align-items: center;
}

.project-spans{
  display: flex;
  vertical-align: center;
}

.contact-sec{
  text-align: center;
  padding: 150px 0;
  
}


.cont-txt{
  font-family: 'description', sans-serif;
  font-size: 35px;
  padding: 10px 0;
}

.cont-description{
  font-family: 'sub sud font', sans-serif;
  padding: 10px 150px;
  margin-bottom: 70px;
}

.say-hello{
    padding: 10px 30px;
    background-color: #020122;
    background-color: var(--background);
    color: #fff;
    border: 1px solid #5cf64a;
    border: 1px solid var(--accent-color);
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-family: 'sub sud font', sans-serif;
    text-decoration: none;
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.say-hello:hover{
  box-shadow: 0 0 10px #5cf64a, 0 0 20px #5cf64a;
  box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}

.mobile-soc-icons{
  display: none;
}

@media screen and (min-width: 1400px){
  .maiin-comp{
    padding: 0 250px;
}

.hero{
  padding-top: 150px;
  height: 100vh;
}

}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .maiin-comp{
    padding: 0 0px;
}

#blob{
  display: none;
}

.hero{
  padding-top: 10px;
  height: 100vh;
  margin-top: 150px;
}

.about-sec{
    
  width: 100%;
  align-items: center;
  padding: 50px 0px;
}

.exp-sec{
  width: 100%;
  padding: 50px 0px;
}

.expience-sec{
  width: 100%;
  padding: 50px 0px;
}

.mobile-soc-icons{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.micn{
  padding: 10px 0px;
    font-size: 2em;
    color: #5cf64a;
    color: var(--accent-color);
    z-index: 2;
    width: 15%;
}

.micn:hover{
  font-size: 2.2em;
  color: #5cf64a;
  color: var(--accent-color);
  cursor: pointer;
  color: #ffffff;
}

}

@media only screen and (max-width: 767px) {

  html{
    margin: 0;
  }

  .maiin-comp{
    padding: 0 0px;
}

#blob{
  display: none;
}

.hero{
  padding-top: 10px;
  height: 80vh;
}

.light-shade{
  font-size: 30px;
  width: 100%;
}

.dark-shade{
  font-size: 30px;
  width: 100%;
}

.description{
  font-size: 15px;
  width: 90%;
}

.about-sec{
    
  width: 100%;
  align-items: center;
  padding: 50px 0px;
}

.about-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
}

.wrap-text{
  width: 100%;
}

.wrap-img{
  margin-top: 20px;
  width: 100%;
}

.exp-sec{
  width: 100%;
  padding: 50px 0px;
}

.expience-sec{
  width: 100%;
  padding: 50px 0px;
}

.ab-txt{
 width: auto;
}

.ab-line{
  width: 50%;
}

.left-side{
  display: none;
}

.ndleft-side{
  display: none;
}

.rdleft-side{
  display: none;
}

.right-side{
  text-align: left;
  width: 100%;
  order: 2;
}

.ndright-side{
  text-align: left;
  width: 100%;
  order: 2;
}

.rdright-side{
  text-align: left;
  width: 100%;
  order: 2;
}

.proj-img{
  width: 100%;
  height: 200px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Felimu-world-landing.png?alt=media&token=4310147a-5e50-449f-b7ac-968de60df351&_gl=1*1jecd6e*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY0ODguMC4wLjA.) ;
  margin-bottom: -50px;
  border-radius: 5px;
  background-position: center;
  border-radius: 5px;
  background-size: 100%;

}

.ndproj-img{
  width: 100%;
  height: 200px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Fprep-landing.png?alt=media&token=6500f777-4b51-4629-a8e8-7f8b03ea13cb&_gl=1*r7oitr*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY1MzAuMC4wLjA.) ;
  margin-bottom: -50px;
  border-radius: 5px;
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
}

.rdproj-img{
  width: 100%;
  height: 200px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-6e4f7.appspot.com/o/portfolio-imgs%2Flearn-landing.png?alt=media&token=6a2fe401-23e1-4a47-abcf-8a4b2ba768b1&_gl=1*qn2r5t*_ga*MTIyNTgyMDQxMi4xNjg1NTIzNDgw*_ga_CW55HF8NVT*MTY4NTk2NTc3OC4xMi4xLjE2ODU5NjY2NzcuMC4wLjA.) ;
  margin-bottom: -50px;
  border-radius: 5px;
  background-position: center;
  border-radius: 5px;
  background-size: 100%;
}



.accordion-container{
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
}

.menu-container{
  display: flex;
  flex-direction: row;
  width: 99%;
}

.content-container{
  width: 100%;
}

.content-item {
  display: none;
  padding-top: 10px;
  font-family: 'sub sud font', sans-serif;
  width: 100%; 
}

.expience-sec{
  width: auto;
  padding: 50px 0px;
}

.contact-sec{
  padding: 50px 0;
}

.cont-description{
  padding: 10px 0px;
}

.mobile-soc-icons{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.micn{
  padding: 10px 0px;
    font-size: 2em;
    color: #5cf64a;
    color: var(--accent-color);
    z-index: 2;
    width: 15%;
}

.micn:hover{
  font-size: 2.2em;
  color: #5cf64a;
  color: var(--accent-color);
  cursor: pointer;
  color: #ffffff;
}
}
@font-face {
    font-family: 'title font';
    src: url('/public/fonts/causten-extrabold.otf') format('opentype');
  }

  @font-face {
    font-family: 'description font';
    src: url('/public/fonts/dejavusans-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'sub sud font';
    src: url('/public/fonts/sourcecodepro-regular.ttf') format('truetype');
  }


.email-hold{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    right: -70px;
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
}

.email-div{
    display: flex;
    flex-direction: column;
    padding: 0px;
    height: 250px;
}

.email{
    
    font-size: 1em;
    color: #999;
    cursor: pointer;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-left: -120px;
    padding: 0;
    font-family: 'sub sud font', sans-serif;
    text-decoration: none;
}

.email:hover{
  font-size: 1.01em;
  color: var(--accent-color);
  cursor: pointer;
}

.line {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }
  
  hr{
      margin: 0;
      padding: 0;
  }
  
  .soc-line {
    width: 300px;
    height: auto;
    border: 1px solid #999;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation: color-change 15s ease-in-out infinite;
            animation: color-change 15s ease-in-out infinite;
    margin: 0;
    padding: 0;
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .email-hold{
      display: none;
  }
  }

  @media only screen and (max-width: 767px) {
    .email-hold{
      display: none;
  }
  }
  :root {
    --background: #020122;
    --text-color-white: #f8f8f8;
    --image-overlay: #6e0e0a;
    --accent-color: #5cf64a;
  }

.main-soc{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 40px;
    -webkit-transform: translateY(-3%);
            transform: translateY(-3%);
    
    
}

.soc-icons{
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
}

.icn{
    padding: 10px 0px 10px 10px;
    font-size: 2em;
    color: #5cf64a;
    color: var(--accent-color);
    z-index: 2;
    width: 15%;
}


.icn:hover{
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  color: #5cf64a;
  color: var(--accent-color);
  cursor: pointer;
  color: #ffffff;
}

.line {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: -125px;
  padding: 0;

}

hr{
    margin: 0;
    padding: 0;
}

.soc-line {
  width: 300px;
  height: auto;
  border: 1px solid #999;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation: color-change 15s ease-in-out infinite;
          animation: color-change 15s ease-in-out infinite;
  margin: 0;
  padding: 0;
}

@-webkit-keyframes color-change {
  0% {
    border: 1px solid #999;
  }
  25% {
    border: 1px solid red;
  }

  50% {
    border: 1px solid green;
  }
  100% {
    border: 1px solid #999;
  }
}

@keyframes color-change {
  0% {
    border: 1px solid #999;
  }
  25% {
    border: 1px solid red;
  }

  50% {
    border: 1px solid green;
  }
  100% {
    border: 1px solid #999;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .main-soc{
    display: none;
}
}

@media only screen and (max-width: 767px) {
  .main-soc{
    display: none;
}
}
